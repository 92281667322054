import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Wrap,
  HStack,
  Image,
  Link,
  Spinner,
  Text,
  VStack,
  UnorderedList,
  ListItem,
  Show,
} from '@chakra-ui/react';

import Loader from '@modules/common-ui/components/global-loader';
import { landingPageStyles } from './styles';
import { getAffiliateProfile } from '@app/modules/core/aws/lambdas/affiliates';
import { IGetAffiliateResponse } from '@app/modules/core/aws/lambdas/affiliates/interfaces';
import { ConditionSeparator } from '@app/modules/common-ui/components/condition-separator';
import UnAuthHeader from '@app/modules/common-ui/components/unAuthHeader';
import UnAuthFooter from '@app/modules/common-ui/components/unAuthFooter';
import { ImgAvatar } from '@app/modules/common-ui/components/profile-basic-info/ImgAvatar';
import { loaderStyles } from '@app/modules/common-ui/components/global-loader/styles';
import { SignUpForm } from '../sign-up/SignUp';
import { useAppEntryParams } from '@modules/core/hooks';

const welcomeImageRight = require('@assets/images/GreenBlackWhiteRight.jpg');
const welcomeImageNeutral = require('@assets/images/GreenBlackWhiteNeutral.jpg');
// const familyCooking = require('@assets/images/familyMealPrep.jpg');
// const familySunset = require('@assets/images/familySunset.jpg');

function AffiliateLandingPage(): ReactElement {
  const { affiliateUserName } = useParams();
  const isLoading = false;
  const [affiliateProfile, setAffiliateProfile] = useState<IGetAffiliateResponse | undefined>();
  const [err, setErr] = useState<string | undefined>();
  const appEntryParams = useAppEntryParams();

  useEffect(() => {
    getAffiliateProfile(affiliateUserName as string)
      .then((affiliateData) => {
        setAffiliateProfile(affiliateData);
        appEntryParams.setAffiliateManually(affiliateUserName as string);
        // console.log(affiliateData);
      })
      .catch((error) => {
        setErr(error.message);
      });
  }, []);

  const licenseNeedsPayment = affiliateProfile?.subscriptionModel !== 'Bulk License';

  function getDiscount(): number {
    const baseFamilyPrice = affiliateProfile?.basePrice?.['Price Annual Family Plan'] ?? 399;
    const familyPrice = affiliateProfile?.promotion?.['Price Annual Family Plan'] ?? 349;
    const baseIndividualPrice =
      affiliateProfile?.basePrice?.['Price Annual Individual Plan'] ?? 249;
    const individualPrice = affiliateProfile?.promotion?.['Price Annual Individual Plan'] ?? 199;
    return Math.round(
      Math.max(
        (baseFamilyPrice - familyPrice) / baseFamilyPrice,
        (baseIndividualPrice - individualPrice) / baseIndividualPrice
      ) * 100
    );
  }

  return (
    <VStack bg='white'>
      <UnAuthHeader activeLink='' />
      <HStack h='100px' />
      {isLoading ? (
        <Loader />
      ) : (
        <ConditionSeparator
          condition={!err}
          target={
            <VStack w='100%' gap='10px' paddingBottom='20px'>
              {affiliateProfile?.logo && (
                <Wrap {...landingPageStyles.sponsorBox}>
                  <Link href={`https://${affiliateProfile?.website}`}>
                    <ImgAvatar file={affiliateProfile!.logo} style={{ borderRadius: '0' }} />
                  </Link>
                  <VStack alignItems='leading'>
                    <Text {...landingPageStyles.welcomeAdditionalText}>
                      Lved Plan Sponsored by: <b>{affiliateProfile?.affiliateName ?? ''}</b>
                    </Text>
                    <Link href={`https://${affiliateProfile?.website}`}>
                      {`https://${affiliateProfile?.website}`}
                    </Link>
                    {licenseNeedsPayment && (
                      <div>
                        <Text>
                          Discount offered: <b>{getDiscount()}%</b> off
                        </Text>
                        <Text {...landingPageStyles.footnote}>
                          *Discount automatically applied on plan selection
                        </Text>
                      </div>
                    )}
                  </VStack>
                </Wrap>
              )}
              {!affiliateProfile && <Spinner {...loaderStyles.spinner} />}
              <Wrap {...landingPageStyles.wrapContainer}>
                <VStack {...landingPageStyles.promoTextContainer}>
                  <Show breakpoint='(min-width: 1125px)'>
                    <VStack w='100%' justifyItems='center'>
                      <Image {...landingPageStyles.welcomeImage} src={welcomeImageRight} />
                    </VStack>
                  </Show>
                  <Show breakpoint='(max-width: 1124px)'>
                    <Image {...landingPageStyles.welcomeImage} src={welcomeImageNeutral} />
                  </Show>
                  <Text {...landingPageStyles.promoTextHighlight}>
                    Estate Planning for the Modern Age
                  </Text>
                  <Text {...landingPageStyles.promoTextHighlight}>
                    Let&apos;s make it easy for you to create, share, and maintain your estate plan.
                  </Text>
                  <Text {...landingPageStyles.promoTextHighlight}>
                    Lved is a complete solution including:
                  </Text>
                  <UnorderedList {...landingPageStyles.benefitsList}>
                    <ListItem>Unlimited document updates</ListItem>
                    <ListItem>Digital and physical assets</ListItem>
                    <ListItem>Storage of all legal documents with passwords + more</ListItem>
                    <ListItem>Tools to preserve + share memories + traditions</ListItem>
                    <ListItem>Easy personal property bequests</ListItem>
                    <ListItem>Automatic document delivery to designated contacts</ListItem>
                    <ListItem>Guided follow-through</ListItem>
                    <ListItem>Bank-level security with selective sharing</ListItem>
                  </UnorderedList>
                  <Text>You get everything in one secure place</Text>
                  <Text>There is no reason to wait to protect your family and your legacy.</Text>
                  {licenseNeedsPayment && (
                    <div>
                      <Text>
                        Streamline your Estate Planning with a <b>{getDiscount()}%</b> discount
                        through {affiliateProfile?.affiliateName}.
                      </Text>
                      <Text>
                        Individual plans are discounted to&nbsp;
                        <b>
                          ${affiliateProfile?.promotion?.['Price Annual Individual Plan'] ?? 199}
                        </b>
                        &nbsp;to start.
                      </Text>
                    </div>
                  )}
                </VStack>
                <SignUpForm transparentBackground showLogo={false} />
              </Wrap>
            </VStack>
          }
          defaultTarget={
            <Text {...landingPageStyles.errorText}>
              There was an error retrieving the affiliate&apos;s content. Please check the URL and
              try again. Error: {err}
            </Text>
          }
        />
      )}
      <UnAuthFooter />
    </VStack>
  );
}

export default AffiliateLandingPage;
