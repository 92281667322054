import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { HStack, Link, Spinner, Text, VStack, Wrap } from '@chakra-ui/react';

import { affiliateDirectoryStyles } from './styles';
// import { getAffiliateProfile } from '@app/modules/core/aws/lambdas/affiliates';
import {
  getAffiliateDirectory,
  getAffiliateProfile,
} from '@app/modules/core/aws/lambdas/affiliates';
import {
  IGetAffiliateDirectoryToken,
  IGetAffiliateResponse,
} from '@app/modules/core/aws/lambdas/affiliates/interfaces';
import { ConditionSeparator } from '@app/modules/common-ui/components/condition-separator';
import UnAuthHeader from '@app/modules/common-ui/components/unAuthHeader';
import UnAuthFooter from '@app/modules/common-ui/components/unAuthFooter';
import { loaderStyles } from '@app/modules/common-ui/components/global-loader/styles';
import { ImgAvatar } from '@app/modules/common-ui/components/profile-basic-info/ImgAvatar';

function AffiliateDirectory(): ReactElement {
  const targetRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [affiliatesToLoad, setAffiliatesToLoad] = useState<string[]>([]);
  const [affiliateRecords, setAffiliateRecords] = useState<Map<string, IGetAffiliateResponse>>(
    new Map()
  );
  const [continueWithToken, setContinueWithToken] = useState<
    IGetAffiliateDirectoryToken | undefined
  >();
  const [err, setErr] = useState<string | undefined>();
  const affiliatesToLoadRef = useRef(affiliatesToLoad);
  const continueWithTokenRef = useRef(continueWithToken);

  useEffect(() => {
    affiliatesToLoadRef.current = affiliatesToLoad;
  }, [affiliatesToLoad]);
  useEffect(() => {
    continueWithTokenRef.current = continueWithToken;
  }, [continueWithToken]);

  function getMoreAffiliates() {
    setIsLoadingMore(true);
    if (affiliatesToLoadRef.current.length) {
      // There are affiliates we know about need to be loaded.
      const affiliateToLoad = affiliatesToLoadRef.current.shift()!;
      setAffiliatesToLoad(affiliatesToLoadRef.current);
      getAffiliateProfile(affiliateToLoad)
        .then((profile) => {
          setAffiliateRecords((prevAffiliateRecords) => {
            prevAffiliateRecords.set(affiliateToLoad, profile);
            return prevAffiliateRecords;
          });
          affiliateRecords.set(affiliateToLoad, profile);
        })
        .catch((affErr) => {
          setErr(affErr.message);
        })
        .finally(() => {
          setIsLoadingMore(false);
        });
    } else if (continueWithTokenRef.current) {
      // No more affiliates known, look for more...
      getAffiliateDirectory(continueWithTokenRef.current)
        .then((directoryRes) => {
          setAffiliatesToLoad(directoryRes.userNames ?? []);
          setContinueWithToken(directoryRes.continueWithToken);
        })
        .catch((error) => {
          setErr(error.message);
        })
        .finally(() => {
          setIsLoadingMore(false);
        });
    } else {
      setIsLoadingMore(false);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    getAffiliateDirectory()
      .then((directoryRes) => {
        setAffiliatesToLoad(directoryRes.userNames);
        setContinueWithToken(directoryRes.continueWithToken);
      })
      .catch((error) => {
        setErr(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  function AffiliateBlurb(props: {
    affiliate?: IGetAffiliateResponse;
    username: string;
  }): ReactElement {
    const { affiliate, username } = props;
    if (affiliate)
      return (
        <VStack {...affiliateDirectoryStyles.container}>
          <Link color='link' href={`https://app.lved.com/partner/${username}`}>
            <ImgAvatar file={affiliate!.logo} style={{ borderRadius: '0' }} />
          </Link>
          <VStack alignItems='leading'>
            <b>{affiliate.affiliateName ?? ''}</b>
            <Link color='link' href={`https://app.lved.com/partner/${username}`}>
              Lved landing page
            </Link>
            <Link color='link' href={`https://${affiliate.website}`}>
              Visit Partner&apos;s Website
            </Link>
          </VStack>
        </VStack>
      );
    return <Text>Affiliate missing</Text>;
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          getMoreAffiliates();
        }
      },
      { threshold: 0 }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [isLoadingMore, continueWithToken, affiliatesToLoad]);

  const moreToLoad = !isLoadingMore && (continueWithToken || affiliatesToLoad.length > 0);
  return (
    <VStack bg='white'>
      <UnAuthHeader activeLink='' />
      <HStack h='100px' />
      {isLoading ? (
        <Spinner {...loaderStyles.spinner} />
      ) : (
        <ConditionSeparator
          condition={!err}
          target={
            <VStack w='100%' paddingBottom='20px' paddingTop='20px'>
              <Wrap {...affiliateDirectoryStyles.directoryWrapper}>
                {Array.from(affiliateRecords?.keys()).map((affiliateName) => {
                  return (
                    <AffiliateBlurb
                      affiliate={affiliateRecords.get(affiliateName)}
                      username={affiliateName}
                      key={affiliateName}
                    />
                  );
                })}
                {isLoadingMore && <Spinner {...loaderStyles.spinner} />}
                {moreToLoad && (
                  <div ref={targetRef}>
                    <Text>loading...</Text>
                  </div>
                )}
              </Wrap>
            </VStack>
          }
          defaultTarget={
            <Text {...affiliateDirectoryStyles.errorText}>
              There was an error retrieving the affiliate&apos;s content. Please check the URL and
              try again. Error: {err}
            </Text>
          }
        />
      )}
      <UnAuthFooter />
    </VStack>
  );
}

export default AffiliateDirectory;
